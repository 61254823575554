<template>
  <div class="account">
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="mainbody">
        <div class="mainheader">个人信息</div>
        <div class="maincontent admin_info">
          <div>员工编号：{{ userinfo.userNumber }}</div>
          <div>姓名：{{ userinfo.userName }}</div>
          <div>部门：{{ userinfo.deptName }}</div>
          <div>职务：{{ userinfo.roleName }}</div>
        </div>
      </div>
      <div class="mainbody">
        <div class="mainheader">密码设置</div>
        <div class="maincontent edit_pd">
          <Form
            ref="formCustom"
            :model="formCustom"
            :rules="ruleCustom"
            :label-width="80"
          >
            <Row>
              <Col span="10">
                <FormItem label="旧密码" prop="oldPasswd">
                  <Input
                    type="password"
                    v-model="formCustom.oldPasswd"
                    placeholder="请输入当前密码"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="10">
                <FormItem label="新密码" prop="newPasswd">
                  <Input
                    type="password"
                    v-model="formCustom.newPasswd"
                    placeholder="请输入新密码"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="10">
                <FormItem label="确认密码" prop="newPasswdCheck">
                  <Input
                    type="password"
                    v-model="formCustom.newPasswdCheck"
                    placeholder="请再次输入新密码"
                  ></Input>
                </FormItem>
              </Col>
            </Row>
            <FormItem>
              <Button
                class="white-btn"
                @click="goIndex"
                style="margin:0px 30px 0px 8px"
                >取消</Button
              >
              <Button type="primary" @click="handleSubmit('formCustom')"
                >保存</Button
              >
            </FormItem>
          </Form>
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  name: "admin",
  data() {
    const validateOld = (rule, value, callback) => {
      // console.log(value)
      // debugger
      callback();
    };
    const validatePass = (rule, value, callback) => {
      const reg = /^[0-9A-Za-z]{6,16}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("密码支持6-16位英文和数字！"));
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.formCustom.model.newPasswd) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      userinfo: {
        userNumber: "", // 员工编号
        userName: "", // 姓名
        deptName: "", // 部门
        roleName: "" // 职务
      },
      formCustom: {
        oldPasswd: "",
        newPasswd: "",
        newPasswdCheck: ""
      },
      ruleCustom: {
        oldPasswd: [{ validator: validateOld, trigger: "blur" }],
        newPasswd: [{ validator: validatePass, trigger: "blur" }],
        newPasswdCheck: [{ validator: validatePassCheck, trigger: "blur" }]
      }
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    handleSubmit(name) {
      const md5oldPasswd = md5(this.formCustom.oldPasswd);
      const md5newPasswd = md5(this.formCustom.newPasswd);
      this.$refs[name].validate((valid) => {
        if (valid) {
          const params = {
            oldPwd: md5oldPasswd,
            newPlaintPwd: this.formCustom.newPasswd,
            newCipherPwd: md5newPasswd
          };
          this.axios({
            url: "/dtsum/zongtong/organization/LoginController/changePwd",
            method: "post",
            data: params
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.$Message.success("修改成功");
                window.sessionStorage.clear();
                window.localStorage.clear();
                this.$router.push({
                  path: "/"
                });
              } else {
                this.$Message.warning(res.data.msg);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$Message.error("保存失败!");
        }
      });
    },
    goIndex() {
      this.$router.push({ name: "Index" });
    }
  }
};
</script>

<style lang="scss" scoped>
.account {
  .mainbody {
    margin-top: 30px;
    box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15),
      0px 0px 0px 1px rgba(63, 63, 68, 0.05);
    border-radius: 2px;
    .mainheader {
      background: #f5f5f5;
      height: 52px;
      line-height: 52px;
      padding: 0px 16px;
    }
    .maincontent {
      padding: 0px 16px;
    }
    .admin_info {
      display: flex;
      height: 115px;
      div {
        width: 25%;
        line-height: 115px;
      }
    }
    .edit_pd {
      padding-top: 30px;
      height: 330px;
    }
  }
}
</style>
